import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';

const CartButton = ({ onClick }) => {
  const { cartCount } = useSelector(state => state.cart);
  const [updateKey, setUpdateKey] = useState(0);

  useEffect(() => {
    cartCount > 1 ? setUpdateKey(updateKey + 1) : setUpdateKey(0);
  }, [cartCount]);

  return (
    cartCount > 0 && (
      <motion.div
        key={updateKey}
        initial={updateKey > 0 ? { opacity: 1, scale: 1 } : { opacity: 0, scale: 0.6 }}
        animate={updateKey > 0 ? { opacity: 1, scale: 1.05 } : { opacity: 1, scale: 1 }}
        transition={{
          type: 'spring',
          damping: 5,
          stiffness: 1000,
          restDelta: 0,
          delay: 0.3,
          ease: [0, 0.71, 0.2, 0.1]
        }}
        className="cart"
        onClick={() => onClick()}
      >
        <svg role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
          <path fill="none" strokeWidth="2" strokeMiterlimit="10" d="M44 18h10v45H10V18h10z" />
          <path fill="none" strokeWidth="2" strokeMiterlimit="10" d="M22 24V11c0-5.523 4.477-10 10-10s10 4.477 10 10v13" />
        </svg>
        <div className="counter">{cartCount}</div>

      </motion.div>
    )
  );
};

export default CartButton;
