import { requestToken } from '../config';

const getRequestConfig = (token) => {
  const config = {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    }
  };

  config.headers.Authorization = token || requestToken;
  return config;
};

export {
  getRequestConfig
};
