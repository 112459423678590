import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Plus from '../../../../assets/images/plus.png';
import Minus from '../../../../assets/images/minus.png';
import { changeItemCount } from '../../../../redux/modules/cart';
import styles from './Counter.module.scss';

const Counter = ({
  quantity, cartItem, price
}) => {
  const dispatch = useDispatch();
  const [counter, setCounter] = useState(quantity);

  useEffect(() => {
    setCounter(quantity);
  }, [quantity]);

  const changeCounter = (dec = false) => () => {
    let currentCounter = counter;
    if (dec) {
      if (counter > 1) currentCounter = counter - 1;
    } else {
      currentCounter = counter + 1;
    }

    setCounter(currentCounter);
    dispatch(changeItemCount({
      menu_item: cartItem.menu_item, counter: currentCounter, price
    }));
  };

  return (
    <div className={styles.count}>
      <button onClick={changeCounter(true)}>
        <img src={Minus} alt="minus" />
      </button>
      {counter}
      <button onClick={changeCounter()}>
        <img src={Plus} alt="plus" />
      </button>
    </div>
  );
};

export default Counter;
