import { React, useState } from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import cn from 'classnames';
import axios from 'axios';
import { urlPrefix } from '../../config';
import { getRequestConfig } from '../../helpers/apiHelper'
import {phoneRegExp} from "../../helpers/utils";

import styles from './/HeaderPopup.module.scss';
import Closed from '../../assets/images/close.png';

const initFormValue = {
    name:"",
    phone:"",
    email:""
}

const HeaderPopup = ({active, setActive}) => {
    const [privacy, setPrivacy] = useState(false);
    const [orderSent, setOrderSent] = useState(false);

    const errorNotify = e => toast.error(e, {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff'
        }
      });

    const validationSchema = yup.object().shape({
        name: yup.string().required('Введите имя'),
        email: yup.string().email('Введите корретный email').required('Введите email'),
        phone: yup.string().matches(phoneRegExp, 'Введите корретный телефон').required('Введите телефон'),
        });
    const formSubmit = async (values) => {
    try {
        const result = await axios.post(urlPrefix + '/email/send',values,getRequestConfig())
    if (result) {
        setOrderSent(true);
        setTimeout(() => {
            setActive();
            setOrderSent(false);
        }, 3000);
    } else {
        throw new Error('Ошибка отправки заявки');
      }
    } catch (e) {
        errorNotify(e.message);
    }
    };

    return (
        <div className={cn(styles.cart_modal, { [styles.active]: !active })}>
            <button className={styles.btn_modal} onClick={(e) => { e.stopPropagation(); e.preventDefault(); setActive(); }}>
                <img src={Closed} alt="exit" />
            </button>
        <div className={styles.wrapper}>
            {orderSent && <div className={styles.orderSent}>Спасибо! Ваша заявка отправлена</div>}
            <h1>Заявка на установку</h1>
            <h2>Заполни форму, мы свяжемся в течение 15 минут и ответим на все вопросы</h2>
        <Formik
            initialValues={initFormValue}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
                formSubmit(values, resetForm());
            }}
            >
            {({
                values, touched, errors, handleChange
            }) => (
                <Form noValidate autoComplete="off" >
                <input
                    className={cn({ [styles.invalid]: errors.name && touched.name })}
                    type="text"
                    name="name"
                    placeholder="Имя"
                    value={values.name}
                    onChange={handleChange}
                />
                <label htmlFor="name">{errors.name}</label>
                <input
                    className={cn({ [styles.invalid]: errors.phone && touched.phone })}
                    type="tel"
                    name="phone"
                    placeholder="+7 (999) 999-9999"
                    pattern="[0-9]*"
                    value={values.phone}
                    onChange={handleChange}
                />
                <label htmlFor="phone">{errors.phone}</label>
                <input
                    className={cn({ [styles.invalid]: errors.email && touched.email })}
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={values.email}
                    onChange={handleChange}
                />
                <label htmlFor="email">{errors.email}</label>
                <div>
                    <input 
                    id='checkbox'
                    type="checkbox"
                    checked={privacy}
                    onChange={()=>setPrivacy(!privacy)}
                    />
                    <label htmlFor='checkbox'>Я согласен с<a href='https://reatme.ru/privacy-policy'> политикой конфиденциальности</a></label>
                </div>
                <button disabled={!privacy} type="submit">Оставить заявку</button>
                </Form>
            )}
            </Formik>
        </div>
    </div>
    );
};

export default HeaderPopup;
