import cn from 'classnames';
import { format } from 'date-fns';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {loadMenu, setActiveDateFilter} from '../../../redux/modules/menu';

const DatesFilter = () => {
  const { dates, activeDate } = useSelector(state => state.menu);
  const dispatch = useDispatch();

  const setActive = date => () => {
    dispatch(setActiveDateFilter(date));
    dispatch(loadMenu(date));
  };

  return (
    <div className="filter">
      <p>Выберите дату доставки:</p>
      {
        dates.map(date => (
          <span
            key={`date-${date}`}
            className={cn({ active: activeDate === date })}
            onClick={setActive(date)}
          >
            {format(new Date(date), 'dd.MM')}
          </span>
        ))
      }
    </div>
  );
};

export default DatesFilter;

