import React, { useState } from 'react';
import cn from 'classnames';
import logo from '../../assets/images/reatme-logo.svg';
import styles from './Header.module.scss';
import HeaderPopup from '../../components/HeaderPopup/HeaderPopup';


const Header = () => {
  const [isHeaderActive, setHeaderActive] = useState(false);
  document.body.addEventListener('scroll', () => {
    setHeaderActive(document.body.scrollTop >= 100);
  });
  
  const [modalActive, setModalActive] = useState(false);

  return (
    <header className={cn(styles.header, { [styles.active]: isHeaderActive })}>
      <div className={styles.container}>
        <img src={logo} alt="Logo" />
        <input id={styles.burger} type="checkbox" />
        <label htmlFor={styles.burger}>
          <span />
          <span />
          <span />
        </label>
        <nav className={styles.nav}>
          <a href="https://reatme.ru/about">О нас</a>
          <div className={styles.list}>
            Наши предложения
            <ul className={styles.hidden}>
                  <li><a href="https://reatme.ru/micromarket">Микромаркет</a></li>
                  <li><a href="https://reatme.ru/vendingmachine">Вендинг</a></li>
                  <li><a href="https://reatme.ru/cafes">Еда для кафе</a></li>
                  <li><a href="https://reatme.ru/cafe-in-office">Кафе самообслуживания</a></li>
                </ul>
          </div>
          <a href="https://reatme.ru/contacts">Контакты</a>
          <a href="tel:+74952588444">+7 495 25 88 444</a>
          <a href="https://t.me/ReatmeBot">
            <svg width="30px" height="30px" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M50 100c27.614 0 50-22.386 50-50S77.614 0 50 0 0 22.386 0 50s22.386 50 50 50Zm21.977-68.056c.386-4.38-4.24-2.576-4.24-2.576-3.415 1.414-6.937 2.85-10.497 4.302-11.04 4.503-22.444 9.155-32.159 13.734-5.268 1.932-2.184 3.864-2.184 3.864l8.351 2.577c3.855 1.16 5.91-.129 5.91-.129l17.988-12.238c6.424-4.38 4.882-.773 3.34.773l-13.49 12.882c-2.056 1.804-1.028 3.35-.129 4.123 2.55 2.249 8.82 6.364 11.557 8.16.712.467 1.185.778 1.292.858.642.515 4.111 2.834 6.424 2.319 2.313-.516 2.57-3.479 2.57-3.479l3.083-20.226c.462-3.511.993-6.886 1.417-9.582.4-2.546.705-4.485.767-5.362Z"
                fill="#ff7f41"
              />
            </svg>
          </a>
          <button onClick={()=>setModalActive(true)}>
            Хочу микромаркет
          </button>
        </nav>
      </div>
      <HeaderPopup active={modalActive} setActive={setModalActive}/>
    </header>
  );
};

export default Header;
