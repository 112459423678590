import React from 'react';

import styles from './NewBadge.module.scss'

const NewBadge = () => {
  return (
    <div className={styles.NewBadge}>
      Новинка
    </div>
  );
};

export default NewBadge;
