import React, { useEffect } from 'react';
import { formatter } from '../../../helpers/utils';
import styles from './CartItem.module.scss';
import Delete from '../../../assets/images/delete.png';
import Counter from './Counter/Counter';
import { useSelector } from 'react-redux';
import cn from 'classnames'

const CartItem = ({
  cartItem, menuItem, removeCartItem, moveToOtherDate, showErrorNotification
}) => {
  const handleRemoveCartItem = () => () => {
    removeCartItem(cartItem.menu_item, menuItem.good_price);
  };
  const { activeDate } = useSelector(state => state.menu);
  useEffect(() => {
    if (cartItem.sku !== menuItem.sku) {
      showErrorNotification();
      moveToOtherDate(cartItem.menu_item, menuItem.good_price);
    }
  }, [menuItem.sku]);

  if (menuItem && (cartItem.sku === menuItem.sku)) {
    return (
      <div className={cn(styles.product, { [styles.unavailable]: cartItem.date !== activeDate })}>
        <div className={styles.product_image}>
          <img src={menuItem.img} />
        </div>
        <div className={styles.product_title}>
          <a href="">{menuItem.good_name}</a>
        </div>
        <div className={styles.product_count}>
          <Counter quantity={cartItem.quantity} price={menuItem.good_price} cartItem={cartItem} />
        </div>
        <div className={styles.product_price}>
          <p>
            {formatter.format((menuItem.good_price / 100) * cartItem.quantity)}
          </p>
        </div>
        <div className={styles.product__control}>
          <button onClick={handleRemoveCartItem()} type="button">
            <img src={Delete} alt="delete" />
          </button>
        </div>
      </div>
    );
  } return <></>;
};

export default CartItem;
