import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import reduxThunk from 'redux-thunk';
import * as reducers from './modules/index';


const middlewares = [];

if (process.env.NODE_ENV === `development`) {
  const reduxLogger = createLogger({
    diff: true,
    collapsed: true
  });
  middlewares.push(reduxLogger);
}

middlewares.push(reduxThunk);
const createStoreWithMiddleware = composeWithDevTools(applyMiddleware(...middlewares))(createStore);

const reducer = combineReducers(reducers);

const configureStore = initialState => createStoreWithMiddleware(reducer, initialState);
export default configureStore;
