import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeItem } from '../../../../redux/modules/cart';
import { isHasProductInCart } from '../../../../helpers/utils';



const MenuItemCounter = ({ quantity, setQuantity, menuItem }) => {
  const dispatch = useDispatch();
  const items = useSelector(state => state.cart.items);

  const changeCounter = (dec = false) => () => {
    let currentCounter = quantity;
    if (dec) {
      if (quantity > 1) {
        currentCounter = quantity - 1;
      } else if (isHasProductInCart(items, menuItem)) {
        dispatch(removeItem(menuItem.good_id, menuItem.good_price));
      }
    } else {
      currentCounter = quantity + 1;
    }

    setQuantity(currentCounter);
  };

  return (
    <div className="counter">
      <span onClick={changeCounter(true)} />
      {quantity}
      <span onClick={changeCounter()} />
    </div>
  );
};

export default MenuItemCounter;
