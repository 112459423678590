import React from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveFilter } from '../../../redux/modules/menu';

const CategoriesFilter = () => {
  const { categories, activeFilterIndex } = useSelector(state => state.menu);
  const dispatch = useDispatch();

  const setActive = id => () => {
    dispatch(setActiveFilter(id));
  };

  return (
    <div className="filter">
      {
        categories.map(category => (
          <span
            key={`category-${category.id}`}
            className={cn({ active: activeFilterIndex === category.id })}
            onClick={setActive(category.id)}
          >
            {category.name}
          </span>
        ))
      }
      <span
        className={cn({ active: activeFilterIndex === 0 })}
        onClick={setActive(0)}
      >
        Все
      </span>
    </div>
  );
};

export default CategoriesFilter;
