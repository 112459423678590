import React from 'react';
import vk from '../../assets/images/vk-social-network-gr.svg'
import logofooter from '../../assets/images/reatme-footer.svg'
import styles from './Footer.module.scss'
import cn from 'classnames'

const Footer = () => {
    return (
          <footer className={styles.footer}>
            <div className={cn(styles.container,styles.container_grid)}>
              <h1><span>К</span>онтакты</h1>
              <div className={styles.wrapper}>
                <h2>Наши предложения</h2>
                <a href="https://reatme.ru/cafes">Для кафе</a>
                <a href="https://reatme.ru/cafe-in-office">Кафе самообслуживания</a>
                <a href="https://reatme.ru/vendingmachine">Вендинг</a>
                <a href="https://reatme.ru/micromarket">Микромаркет</a>
              </div>
              <div className={styles.wrapper}>
                <h2>О компании</h2>
                <a href="https://reatme.ru/about">О нас</a>
                <a href="https://reatme.ru/contacts">Контакты</a>
              </div>
              <div className={styles.wrapper}>
                <div>
                  <img src={logofooter} alt=""/>
                  <a href="https://vk.com/reatme">
                    <img src={vk} alt=""/>
                  </a>
                </div>
                <p>ООО "Алгоритм" © Все</p>
                <p>Права Защищены.</p>
              </div>
              <div className={styles.wrapper}>
                <a href="https://yandex.ru/maps/213/moscow/house/volokolamskoye_shosse_89/Z04YcgZgTEABQFtvfXRzeHxlYw==/?ll=37.410555%2C55.829067&z=17.16">Москва, Волоколамское шоссе, 89</a>
              </div>
              <div className={styles.wrapper}>
                <a href="tel:+74952588444">+7 495 25 88 444</a>
                <a href="mailto:welcome@reatme.ru">welcome@reatme.ru</a>
              </div>
            </div>
            <div className={styles.container}>
              <a href="https://reatme.ru/privacy-policy">Политика конфиденциальности</a>
            </div>
          </footer>
    );
};

export default Footer;
